
<template>
  <header class="s-header">
    <div class="flex">
      <div class="flex1 flex">
        <router-link to="/" class="logo-home">
          <img :src="logoUrl" alt="">
        </router-link>
        <div class="nav items flex align-center">
          <router-link class="item" to="/spot" :class="{'active': $route.path=='/spot'}" exact><div><p>{{ $t("nav2") }}</p></div></router-link>
          <router-link class="item" v-if="cofigObj.moduleIsOpenFuture==='1'" to="/futures" :class="{'active': $route.path=='/futures'}"><div><p>{{ changeText ? $t('nav3_2') : $t("nav3") }}</p></div></router-link>
          <router-link class="item" v-if="cofigObj.moduleIsOpenC2c==='1'" to="/c2c-sale" :class="{'active': $route.path=='/c2c-sale'}"><div><p>{{ $t("304") }}</p></div></router-link>
          <router-link class="item" v-if="cofigObj.moduleIsOpenPledge==='1'" to="/financial" :class="{'active': $route.path=='/financial'}"><div><p>{{ $t("nav4") }}</p></div></router-link>
        </div>
      </div>
      <div class="uers-msg">
        <div class="flex align-center logined" v-if="userLogin">
          <div class="cursor btn" style="font-size: 16px;" @click="$router.push({name: 'Me'})"><span class="el-icon-user" style="vertical-align:text-bottom;"></span></div>
          <span class="cursor btn" @click="$router.push({name: 'Assets'})">{{ $t(305) }}</span>
        </div>
        <div class="flex align-center" v-else>
          <span class="btn mr10" @click="$router.push({name: 'Login'})">{{ $t(94) }}</span>
          <span  class="btn primary" @click="$router.push({name: 'Register'})">{{ $t(95) }}</span>
        </div>
      </div>
      <div class="langs cursor" @click="showLangModal=true">{{ curLangName }}</div>
    </div>
    <LangModal v-model="showLangModal" @update="switchLang"></LangModal>
  </header>
</template>

<script>
import userControl from "../components/UserControl.vue";
import LangModal from '@/components/LangModal.vue'
import { LANG_OPTIONS } from '@/constants/const.js'
import utils from '@/utils/misc'
import i18n from "../lang";
export default {
  name: "SimpleHeader",
  components: { LangModal },
  data() {
    return {
      showLangModal: false,
      curLang: utils.getLs('locale')||window.configObj.defaultLang||'en-US',
    }
  },
  computed:{
    changeText() {
      return [
        'c1241e1d-ad79-487b-b6f4-fb9d653ddf22', // test
        '3b042e72-f85f-4376-a138-ba1950da8fee', // prepro
        '7b89bc95-1758-46ef-8b9b-869892ee8f67', // res
        '9d28bfe3-6392-411e-b821-ede06d6354a5'
      ].includes(utils.getLs('agentIdentify'))
    },
    userLogin() {
      return !!utils.getLs('token')
    },
    curLangName() {
      let obj = {}
      for (const item of LANG_OPTIONS) {
        if (this.curLang === item.lang) {
          obj = item
          break
        }
      }
      return obj.name
    },
    logoUrl() {
      return window.configObj.logoUri
    },
    appName() {
      return window.configObj.appName
    },
    cofigObj() {
      return window.configObj
    }
  },
  methods: {
    switchLang(obj) {
      i18n.locale = obj.lang;
      this.curLang = obj.lang;
      utils.setLs("locale", obj.lang);
      this.$store.dispatch('getAccountNames')
    }
  }
};
</script>
<style lang="scss" scoped>
.s-header{
  height: 64px;
  line-height: 64px;
  padding: 0px 20px;
  background-color: #000050;
  color: #fff;
  .logo-home{
    display:inline-block;
    height: 100%;
    img{
      width: 55px;
      height: auto;
      vertical-align: middle;
    }
  }
  .nav{
    .item{
      height: 64px;
      margin: 0px 20px;
      border-bottom: 4px solid #181a20;
      &.active,&:hover{
        color: $primaryColor;
        border-bottom: 4px solid $primaryColor;
      }
    }
  }
  .uers-msg{
    .btn{
      cursor: pointer;
      display: inline-block;
      margin-right: 14px;
      &.primary{
        color: #fff;
        background-color: $primaryColor;
        height: 34px;
        line-height: 34px;
        padding: 0px 10px;
        border-radius: 2px;
        &:hover{
          opacity: .8;
        }
      }
    }
    .logined{
      margin-right: 10px;
      .btn{
        margin-right: 0;
        padding: 0px 10px;
        &:hover{
          background-color: #2e3230;
        }
      }
    }
  }
}
</style>
