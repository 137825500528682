
<template>
  <modal
    :visible="value"
    @onClosed="doClose"
    size="xsmall"
  >
    <span slot="head">{{ $t(113) }}</span>
    <div slot="body">
        <div class="lang-item cursor" v-for="item in LANG_OPTIONS" :key="item.lang" :class="{active: curLang===item.lang}" @click="switchLang(item)">
            {{item.name}}
        </div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import {LANG_OPTIONS} from'@/constants/const'
import utils from '@/utils/misc.js'
export default {
  name: 'LangModal',
  components: {Modal},
  props: {
    value: Boolean
  },

  data() {
    return {
        LANG_OPTIONS,
        curLang: utils.getLs('locale')||window.configObj.defaultLang||'en-US',
    }
  },
  computed: {
    countryList() {
      return this.$store.state.countryList
    },
  },
  beforeMount() {
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
  },
  methods: {
    switchLang(obj) {
      this.$emit('update', obj)
    },
    doClose() {
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
    .lang-item{
        display: inline-block;
        margin-top: 16px;
        margin-right: 16px;
        width: calc(33% - 16px);
        height: 40px;
        line-height: 40px;
        padding: 0px 12px;
        &:hover{
            border-radius: 4px;
            background-color: #f5f5f5;
        }
        &.active{
            color: $primaryColor;
        }
    }
</style>