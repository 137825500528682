export default {
  data() {
    return {
      loading: false,
      commonRules: {
        mobile: [
          { 
            validator:(rule,value,callback) => {
              if (this.form.type==='EMAIL') return callback()
              if (value==='') {
                return callback(new Error(this.$t('106')))
              }
              if (!/^\d+$/.test(value)) {
                return callback(new Error(this.$t('107')))
              }
              callback()
            }
          }
        ],
        email: [
          { 
            validator:(rule,value,callback) => {
              if (this.form.type==='MOBILE') return callback()
              if (value==='') {
                return callback(new Error(this.$t('108')))
              }
              if (!/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]+)$/.test(value)) {
                return callback(new Error(this.$t('109')))
              }
              callback()
            }
          }
        ],
        verifyCode: [
          { 
            validator:(rule,value,callback) => {
              if (value==='') {
                return callback(new Error(this.$t('124')))
              }
              if (!/^\d{6}$/.test(value)) {
                return callback(new Error(this.$t('125')))
              }
              callback()
            }
          }
        ],
        password: [
          { required: true, message: this.$t(110) },
          { min: 6, message: this.$t(111) },
          { max: 32, message: this.$t(112) }
        ],
        oldPassword: [
          { required: true, message: this.$t(154) },
          { min: 6, message: this.$t(111) },
          { max: 32, message: this.$t(112) }
        ]
      }
    }
  }
}
